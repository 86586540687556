import React from 'react';
import { Form, Input, DatePicker, SubmitButton, FormItem, Select } from 'formik-antd';
import { Formik } from 'formik';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Col, Row, Tooltip } from 'antd';
import { RiInformationLine } from 'react-icons/ri';
import capitalize from 'lodash/capitalize';
import Yup from '../../../../vendor/yup';
import { mkClearFormTableParams } from '../../../../lib/mkClearFormTableParams';
import { getNotEmptyObjectValues } from '../../../../lib/getNotEmptyObjectValues';
import { TFormDateRangeDivider } from '../FormCreateEditAbTest/FormCreateEditAbTest';
import { PLATFORM_OPTIONS } from '../FormCreateEditAbTest/FormCreateEditAbTest.const.jsx';



const { Option } = Select;


const validationSchema  = Yup.object().shape({
  name: Yup.string(),
  status: Yup.array().nullable(),
  is_published: Yup.string().nullable(),
  created_by: Yup.string().nullable(),
  first_published_by: Yup.string().nullable(),
  published_by: Yup.string().nullable(),
});

const FormAbTestsFilter = ({ onSubmit, initialValues, isSubmitting, formErrors, optionsData }) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialErrors={formErrors}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(getNotEmptyObjectValues(values));
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { values, isValid, setValues, setFieldValue } = props;

        return (
          <Form layout="vertical">
            <Col>
              <FormItem
                className='ant-form-item-col'
                name="name"
                label="Name:"
              >
                <Input
                  name='name'
                  placeholder='Specify full name'
                />
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="status"
                label="Status:"
              >
                <Select
                  mode='multiple'
                  allowClear
                  name="status"
                  value={values.status}
                  placeholder="Select status"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.statuses) ? optionsData.statuses.map((item) => {
                    return (
                      <Option key={item} value={String(item)}>{capitalize(item)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="platforms"
                label="Platforms:"
              >
                <Select
                  mode='multiple'
                  allowClear
                  name="platforms"
                  value={values.platforms}
                  placeholder="Select platforms"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {PLATFORM_OPTIONS.map((option) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem name="is_published" label="Is published:">
                <Select
                  name="is_published"
                  allowClear
                  placeholder="Select value"
                  value={values?.is_published}
                >
                  <Option value='1'>Yes</Option>
                  <Option value='0'>No</Option>
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='date-range-form-item ant-form-item-col'
                name='created_at'
                label={
                  <div className='content-right'>
                    Created at:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <Row>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col disable-ant-form-margin'
                      name='created_at.from'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select min date'
                        name='created_at.from'
                        disabledDate={(date) => !isEmpty(values?.created_at?.to) ? date.isAfter(moment(values.created_at.to).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('created_at.from', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>

                  <Col span={2}>
                    <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                  </Col>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col'
                      name='created_at.to'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select max date'
                        name='created_at.to'
                        disabledDate={(date) => !isEmpty(values?.created_at?.from) ? date.isBefore(values?.created_at?.from) || date.isAfter(moment(new Date()).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('created_at.to', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="created_by"
                label="Created by:"
              >
                <Select
                  allowClear
                  showSearch
                  name="created_by"
                  value={values?.created_by}
                  placeholder="Select user"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.users) ? optionsData.users.map((item) => {
                    return (
                      <Option key={item.id} value={String(item.id)}>{capitalize(item.name)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='date-range-form-item ant-form-item-col'
                name='first_published_at'
                label={
                  <div className='content-right'>
                    First published at:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <Row>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col disable-ant-form-margin'
                      name='first_published_at.from'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select min date'
                        name='first_published_at.from'
                        disabledDate={(date) => !isEmpty(values?.first_published_at?.to) ? date.isAfter(moment(values.first_published_at.to).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('first_published_at.from', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>

                  <Col span={2}>
                    <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                  </Col>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col'
                      name='first_published_at.to'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select max date'
                        name='first_published_at.to'
                        disabledDate={(date) => !isEmpty(values?.first_published_at?.from) ? date.isBefore(moment(values?.first_published_at?.from).utc(false)) || date.isAfter(moment(new Date()).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('first_published_at.to', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="first_published_by"
                label="First published by:"
              >
                <Select
                  allowClear
                  showSearch
                  name="first_published_by"
                  value={values?.first_published_by}
                  placeholder="Select user"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.users) ? optionsData.users.map((item) => {
                    return (
                      <Option key={item.id} value={String(item.id)}>{capitalize(item.name)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='date-range-form-item ant-form-item-col'
                name='published_at'
                label={
                  <div className='content-right'>
                    Last published at:
                    <Tooltip placement="top" title="Time zone - UTC +0">
                      <RiInformationLine size={18} />
                    </Tooltip>
                  </div>
                }
              >
                <Row>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col disable-ant-form-margin'
                      name='published_at.from'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select min date'
                        name='published_at.from'
                        disabledDate={(date) => !isEmpty(values?.published_at?.to) ? date.isAfter(moment(values.published_at.to).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('published_at.from', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>

                  <Col span={2}>
                    <TFormDateRangeDivider>&ndash;</TFormDateRangeDivider>
                  </Col>
                  <Col span={11}>
                    <FormItem
                      className='ant-form-item-col'
                      name='published_at.to'
                    >
                      <DatePicker
                        showTime
                        format='YYYY-MM-DD HH:mm'
                        placeholder='Select max date'
                        name='published_at.to'
                        disabledDate={(date) => !isEmpty(values?.published_at?.from) ? date.isBefore(moment(values?.published_at?.from).utc(false)) || date.isAfter(moment(new Date()).utc(false)) : date.isAfter(moment(new Date()).utc(false))}
                        onChange={(value) => {
                          if (value) {
                            setFieldValue('published_at.to', moment(value).format('YYYY-MM-DD HH:mm'));
                          }
                        }}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </FormItem>
            </Col>

            <Col>
              <FormItem
                className='ant-form-item-col'
                name="published_by"
                label="Last published by:"
              >
                <Select
                  allowClear
                  showSearch
                  name="published_by"
                  value={values?.published_by}
                  placeholder="Select user"
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                >
                  {!isEmpty(optionsData?.users) ? optionsData.users.map((item) => {
                    return (
                      <Option key={item.id} value={String(item.id)}>{capitalize(item.name)}</Option>
                    );
                  }) : null}
                </Select>
              </FormItem>
            </Col>

            <Row gutter={[ 16, 16 ]} style={{ justifyContent: 'center', padding: '10px 0' }}>
              <Col>
                <Button
                  type='secondary'
                  disabled={isEmpty(mkClearFormTableParams(values))}
                  onClick={() => {
                    setValues({});
                  }}
                >
                  Reset
                </Button>
              </Col>
              <Col>
                <SubmitButton
                  loading={isSubmitting}
                  disabled={!isValid || isEqual(initialValues, values)}
                >
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

FormAbTestsFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object,
  optionsData: PropTypes.object,
  initialValues: PropTypes.shape({}).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  values: PropTypes.shape({
    user: PropTypes.string,
    is_published: PropTypes.string,
    status: PropTypes.string,
    created_by: PropTypes.string,
    first_published_by: PropTypes.string,
    published_by: PropTypes.string,
    created_at: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    first_published_at: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    published_at: PropTypes.shape({ from: PropTypes.string, to: PropTypes.string }),
    platforms: PropTypes.string,
  }),
};

FormAbTestsFilter.defaultProps = {
  formErrors: {},
  optionsData: {},
};

export default FormAbTestsFilter;
