import React from 'react';
import { Badge, Button, Popconfirm, Row, Tooltip } from 'antd';
import capitalize from 'lodash/capitalize';
import { DeleteOutlined, EyeOutlined, WarningOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { CONFIGURATIONS_STATUS_COLOR } from '../PageConfigurations/PageConfigurations.const';
import { hasRights, Permissions } from '../../../../const/permissions';
import urlPageConfigMixinDetails from '../../../../urls/urlPageConfigMixinDetails';
import { getDefaultTableSorting } from '../../../../lib/getDefaultTableSorting';
import { EMPTY_VALUE_PLACEHOLDER, renderDateField } from '../../../../const/system';



export const getConfigurationMixinsTableColumns = ({ handleDeleteConfigurationMixin, configurationName, abTestsList }) => [
  {
    title: 'ID',
    width: '10%',
    dataIndex: 'id',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    align: 'center',
    render: (value) => {
      return (
        <Badge color={CONFIGURATIONS_STATUS_COLOR(value)} text={capitalize(value)} />
      );
    },
  },
  {
    title: 'Priority',
    width: '10%',
    align: 'center',
    dataIndex: 'priority',
    render: (value) => <div>{value || EMPTY_VALUE_PLACEHOLDER}</div>,
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('priority'),
  },
  {
    title: 'Created at',
    dataIndex: 'created_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('created_at'),
  },
  {
    title: 'Updated at',
    dataIndex: 'updated_at',
    align: 'center',
    render: (value) => renderDateField(value),
    sorter: true,
    defaultSortOrder: getDefaultTableSorting('updated_at'),
  },
  ...(hasRights([
    Permissions.CONFIGS.CONFIGURATIONS.MIXINS.DELETE,
    Permissions.CONFIGS.CONFIGURATIONS.MIXINS.VIEW_DETAILS,
  ]) ?
    [ {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: '20%',
      render: (item) => {
        const showAbTestsArchivedInfo = () => {

          return item.targets?.some((target) => {
            const conditions = JSON.parse(target.conditions);
            const abTestNameFromTarget = conditions?.find((condition) => condition.ab_test && condition.condition_type === 'include')?.ab_test.name;

            return abTestsList?.some((abTest) => abTest.name === abTestNameFromTarget);
          });
        };

        return (
          <Row className="da-h-100" align="middle" justify="center">
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.DELETE ]) && (
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDeleteConfigurationMixin({ mixinId: item.id })}>
                <Tooltip placement="top" title='Delete configuration mixin'>
                  <Button
                    size="small"
                    type='primary'
                    danger
                    className='btn-with-side-margin'
                  >
                    <DeleteOutlined />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.VIEW_DETAILS ]) && (
              <Tooltip placement="top" title='Mixin details'>
                <Button size="small" type='primary' className='btn-with-side-margin'>
                  <Link
                    to={urlPageConfigMixinDetails({
                      configurationId: item.config_id,
                      configurationName,
                      mixinId: item.id,
                    })}
                  >
                    <EyeOutlined />
                  </Link>
                </Button>
              </Tooltip>
            )}
            {showAbTestsArchivedInfo() && (
              <Tooltip placement="top" title='Mixin targeted at the archived AB test'>
                <Button size="small" type='primary' className='btn-with-side-margin warning-btn'>
                  <WarningOutlined />
                </Button>
              </Tooltip>
            )}
          </Row>
        );
      },
    } ] : []
  ),
];
