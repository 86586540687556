import React from 'react';
import { Form, FormItem, Input, Select, SubmitButton, DatePicker } from 'formik-antd';
import { FieldArray, Formik, FastField } from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Card, Col, Divider, Popconfirm, Row, Spin } from 'antd';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import cloneDeep from 'lodash/cloneDeep';
import { RiAddFill } from 'react-icons/ri';
import Yup from '../../../../vendor/yup';
import { getObjectDeepClean } from '../../../../lib/getObjectDeepClean';
import { mkSelectFilterOption } from '../../../../lib/mkSelectFilterOption';
import { isSmallerThan } from '../../../../lib/isSmallerThan';
import { COUNTRIES_LIST, IPv4_REGEXP, SEMVER_REGEXP } from '../../../../const/system';
import {
  AB_TESTS_LANGUAGES_OPTIONS,
  DEVICE_MANUFACTURES_OPTIONS,
  DEVICE_TYPE_OPTIONS, LAT_TYPES_OPTIONS, LAW_TYPES_OPTIONS, USERS_TYPES_OPTIONS, PLATFORM_OPTIONS,
} from '../../../AbTests/forms/FormCreateEditAbTest/FormCreateEditAbTest.const';
import { AB_TEST_INITIAL_CONDITION_FIELD_VALUES } from '../../../AbTests/pages/PageCreateEditAbTest/PageCreateEditAbTest.const';
import './index.css';



const { RangePicker } = DatePicker;
const { Option } = Select;

const FormCreateEditAbTestButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;
`;

const FormCreateEditAbTestButtonWr = styled.div`
  width: 130px;
  padding: 0 10px 12px 10px;
  margin-top: 20px;
`;

const TFormCreateEditAbTestDivider = styled.div`
  display: flex;
  justify-content: center;
  line-height: 40px;
  color: #dfe6e9;
  font-size: 16px;
`;


const validationSchema = Yup.lazy((values) => {
  const semverField = Yup.string().nullable().matches(SEMVER_REGEXP, 'The value does not match the semver regular expression').max(100, 'Max 100 symbols required');

  return (
    Yup.object().shape({
      name: Yup.string()
        .matches(/^[a-zA-Z\d-_ ]*$/, 'Only latin literatures, numbers, _ and - is allowed')
        .min(5, 'Min 5 symbols required')
        .max(100, 'Max 100 symbols required')
        .required('Name is required'),
      status: Yup.string().required('Required to select status'),
      conditions: Yup.array().of(
        Yup.object().shape({
          condition_type: Yup.string().nullable().required('Required to select condition type'),
          users: Yup.object().shape({
            types: Yup.array().nullable(),
            list: Yup.array().of(Yup.string().min(3, 'Min 3 symbols required').nullable()),
          }).nullable(),
          ip_addresses: Yup.array().of(Yup.string().matches(IPv4_REGEXP, 'Should be IPv4')).nullable(),
          input_os_versions_list: semverField,
          input_app_versions_list: semverField,
          os_versions_min: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-os-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.os_versions_max;

                if (value && maxValue) {
                  return isSmallerThan(value, maxValue) || value === maxValue;
                }
                return true;
              },
            ),
          os_versions_max: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-os-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.os_versions_min;

                if (value && minValue) {
                  return isSmallerThan(minValue, value) || value === minValue;
                }
                return true;
              },
            ),
          app_versions_min: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-app-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.app_versions_max;

                if (value && maxValue) {
                  return isSmallerThan(value, maxValue) || value === maxValue;
                }
                return true;
              },
            ),
          app_versions_max: Yup.string()
            .matches(SEMVER_REGEXP, 'The value does not match the semver regular expression')
            .nullable().test(
              'check-app-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.app_versions_min;

                if (value && minValue) {
                  return isSmallerThan(minValue, value) || value === minValue;
                }
                return true;
              },
            ),
          install_dates_min: Yup.string().nullable(),
          install_dates_max: Yup.string().nullable(),
          ab_test: Yup.object().shape({
            name: Yup.string().nullable(),
            group: Yup.string().nullable().when('name', {
              is: (name) => !isEmpty(name),
              then: () => Yup.string().nullable().required('Required to select ab test group'),
            }),
          }).nullable(),
          install_dates_special: Yup.object().shape({
            seconds: Yup.string(),
            statement: Yup.string().nullable(),
          }).nullable().test(
            'check-install-dates',
            'Fields should not be empty',
            (items) => {
              const objValues = Object.values(items);

              return isEmpty(objValues) || objValues.length === 2;
            },
          ),
          ram_min: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-ram-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.ram_max;

                if (value && maxValue) {
                  return Number(value) <= Number(maxValue);
                }
                return true;
              },
            ),
          ram_max: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-ram-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.ram_min;

                if (value && minValue) {
                  return Number(minValue) <= Number(value);
                }
                return true;
              },
            ),
          device_performance_min: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-device-performance-min',
              'Min value should be less than or equal to the max',
              (value, context) => {
                const { index } = context.options;
                const maxValue = values.conditions[index]?.device_performance_max;

                if (value && maxValue) {
                  return Number(value) <= Number(maxValue);
                }
                return true;
              },
            ),
          device_performance_max: Yup.number().integer()
            .typeError('Specify integer value')
            .min(0, 'Should be equal or more than 0')
            .max(65000, 'Should be less or equal 65000')
            .nullable().test(
              'check-device-performance-max',
              'Max value should be greater than or equal to the min',
              (value, context) => {
                const { index } = context.options;
                const minValue = values.conditions[index]?.device_performance_min;

                if (value && minValue) {
                  return Number(minValue) <= Number(value);
                }

                return true;
              },
            ),
          input_ram_list: Yup.number().integer().typeError('Specify integer value').min(0, 'Should be equal or more than 0').max(65000, 'Should be less or equal 65000').nullable(),
          input_device_performance_list: Yup.number().integer().typeError('Specify integer value').min(0, 'Should be equal or more than 0').max(65000, 'Should be less or equal 65000').nullable(),
          custom_target: Yup.string().matches(/^[a-zA-Z0-9 (){}_:.,+-]*$/, 'Only latin letters, digits and (){}_:.,+- is required')
            .max(255, 'Max 255 symbols required').nullable(),
        }),
      ).required('Condition is required'),
    })
  );
});


const FormCreateEditConfigTargeting = ({
  isMixinTargeting,
  onSubmit,
  onCancel,
  initialValues,
  isSubmitting,
  formErrors,
  isEditForm,
  abTestsList,
  isExperimentsPage,
}) => {
  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      isSubmitting={isSubmitting}
      initialValues={{ ...initialValues, abTestOptions: abTestsList }}
      initialErrors={formErrors}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {(props) => {
        // eslint-disable-next-line react/prop-types
        const { isValid, dirty, values, errors } = props;

        const conditionsCopy = cloneDeep(values.conditions);
        const clearedConditions = !isEmpty(conditionsCopy) ? conditionsCopy.map((item) => getObjectDeepClean(item)) : [];
        const isValidConditionsArr = clearedConditions.map((item) => Object.keys(item).length > 1);
        const clearedConditionsIndexMap = isValidConditionsArr.map((item, index) => !item ? `#${index + 1}` : '').filter((arrItem) => !isEmpty(arrItem));

        return (
          <Spin spinning={isEditForm && isEmpty(initialValues)} tip="Loading form data...">
            <Form layout="vertical" style={{ width: '100%' }}>
              <Row gutter={[ 16, 16 ]}>
                <Col span={24}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Main details
                  </Divider>
                  <Row>
                    <Col span={24}>
                      <Card>
                        <Row gutter={16}>
                          <Col xs={24} md={12} lg={!isEditForm ? 8 : 6}>
                            <FormItem
                              className='ant-form-item-col'
                              name="config_name"
                              label="Configuration name:"
                            >
                              <Input
                                name='config_name'
                                disabled
                              />
                            </FormItem>
                          </Col>
                          {isEditForm && (
                            <Col xs={24} md={12} lg={6}>
                              <FormItem
                                className='ant-form-item-col'
                                name="author_name"
                                label="Author name:"
                              >
                                <Input
                                  name='author_name'
                                  disabled
                                />
                              </FormItem>
                            </Col>
                          )}
                          <Col xs={24} md={12} lg={!isEditForm ? 8 : 6}>
                            <FormItem
                              className='ant-form-item-col'
                              name="name"
                              label={`${isMixinTargeting ? 'Mixin target' : 'Target'} name:`}
                              required={!isEditForm}
                            >
                              <Input
                                fast
                                name='name'
                                placeholder={`Specify ${isMixinTargeting ? 'mixin' : ''} target name:`}
                                disabled={isEditForm}
                              />
                            </FormItem>
                          </Col>

                          <Col xs={24} md={12} lg={!isEditForm ? 8 : 6}>
                            <FormItem
                              className='ant-form-item-col'
                              name="status"
                              label="Status:"
                              required
                            >
                              <Select
                                name="status"
                                placeholder="Select status"
                                value={values.status}
                              >
                                <Option value="enabled">Enabled</Option>
                                <Option value="paused">Paused</Option>
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Divider orientation="left" className='dashboard-widgets-divider'>
                    Conditions
                  </Divider>

                  <FieldArray
                    name="conditions"
                    value={values.conditions}
                  >
                    {({ remove, push }) => {
                      return (
                        <>
                          {values.conditions.map((condition, index) => (
                            <Card
                              /* eslint-disable-next-line react/no-array-index-key */
                              key={index}
                              title={`Condition # ${index + 1}`}
                              extra={
                                <>
                                  {values.conditions.length !== 1 && (
                                    <Popconfirm title="Sure to delete this condition?" onConfirm={() => remove(index)}>
                                      <Button
                                        size='small'
                                        danger
                                        icon={<DeleteOutlined />}
                                      />
                                    </Popconfirm>
                                  )}
                                </>
                              }
                            >
                              {/* eslint-disable-next-line react/no-array-index-key */}
                              <FastField name={`conditions.${index}`} key={index}>
                                {({ field: { name }, form: { setFieldValue } }) => {
                                  return (
                                    <Row gutter={16}>
                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.condition_type`}
                                          label="Condition type:"
                                          required
                                        >
                                          <Select
                                            name={`${name}.condition_type`}
                                            placeholder="Specify condition type"
                                            value={values.conditions[index].condition_type}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.condition_type`, value);
                                            }}
                                          >
                                            <Option value='include'>Include specific locations</Option>
                                            <Option value='exclude'>Exclude these locations</Option>
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.countries`}
                                          label="Countries:"
                                        >
                                          <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            name={`${name}.countries`}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.countries`, value);
                                            }}
                                            placeholder="Select country"
                                            value={values.conditions[index].countries}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {COUNTRIES_LIST.map((option) => {
                                              return (
                                                <Option key={option.code} value={option.code}>
                                                  {`${option.code} - ${option.name}`}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.languages`}
                                          label="Languages:"
                                        >
                                          <Select
                                            allowClear
                                            showSearch
                                            mode="multiple"
                                            name={`${name}.languages`}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.languages`, value);
                                            }}
                                            placeholder="Select language(s)"
                                            value={values.conditions[index]?.languages}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {AB_TESTS_LANGUAGES_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.key} value={option.key}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.device_types`}
                                          label="Device types:"
                                        >
                                          <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            name={`${name}.device_types`}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.device_types`, value);
                                            }}
                                            placeholder="Select device types"
                                            value={values.conditions[index].device_types}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {DEVICE_TYPE_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.value} value={option.value}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.device_manufactures`}
                                          label="Device manufactures:"
                                        >
                                          <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            name={`${name}.device_manufactures`}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.device_manufactures`, value);
                                            }}
                                            placeholder="Select device manufactures"
                                            value={values.conditions[index].device_manufactures}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {DEVICE_MANUFACTURES_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.value} value={option.value}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.device_models`}
                                          label="Device models:"
                                        >
                                          <Select
                                            mode="multiple"
                                            showSearch={false}
                                            allowClear
                                            name={`${name}.device_models`}
                                            value={values.conditions[index].device_models}
                                            placeholder="Specify device model(s)"
                                            dropdownRender={(menu) => (
                                              <div>
                                                {menu}
                                                <Divider style={{ margin: '4px 0' }} />
                                                <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                  <Input
                                                    fast
                                                    size='small'
                                                    style={{ flex: 'auto' }}
                                                    name={`${name}.input_device_model`}
                                                    onKeyDown={(event) => event.stopPropagation()}
                                                    value={values.conditions[index].input_device_model}
                                                    onChange={(event) => {
                                                      setFieldValue(`${name}.input_device_model`, event.target.value, false);
                                                    }}
                                                  />
                                                  <Button
                                                    type="primary"
                                                    size='small'
                                                    disabled={(!isEmpty(values.conditions[index]?.device_models) ?
                                                      values.conditions[index]?.device_models.includes(values.conditions[index]?.input_device_model) : false)
                                                      || isEmpty(values.conditions[index]?.input_device_model)}
                                                    style={{ flex: 'none', margin: '4px', display: 'block', cursor: 'pointer' }}
                                                    onClick={() => {
                                                      setFieldValue(`${name}.device_models`, [ ...values.conditions[index]?.device_models, values.conditions[index]?.input_device_model ], false);
                                                      setFieldValue(`${name}.input_device_model`, '', false);
                                                    }}
                                                  >
                                                    <PlusOutlined />
                                                    Add item
                                                  </Button>
                                                </div>
                                              </div>
                                            )}
                                          />
                                        </FormItem>
                                      </Col>


                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.device_performance`}
                                          label='Device performance:'
                                        >
                                          <Row>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                style={{ margin: 'unset !important', padding: 'unset !important' }}
                                                name={`${name}.device_performance_min`}
                                              >
                                                <Input
                                                  type='number'
                                                  name={`${name}.device_performance_min`}
                                                  onChange={(event) => {
                                                    setFieldValue(`${name}.device_performance_min`, event.target.value);
                                                    setFieldValue(`${name}.device_performance.min`, event.target.value);
                                                  }}
                                                  placeholder='Min performance value'
                                                  disabled={!isEmpty(values.conditions[index]?.device_performance_list) && !values.conditions[index]?.device_performance_min}
                                                />
                                              </FormItem>
                                            </Col>

                                            <Col span={2}>
                                              <TFormCreateEditAbTestDivider>&ndash;</TFormCreateEditAbTestDivider>
                                            </Col>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                name={`${name}.device_performance_max`}
                                              >
                                                <Input
                                                  type='number'
                                                  name={`${name}.device_performance_max`}
                                                  onChange={(event) => {
                                                    setFieldValue(`${name}.device_performance_max`, event.target.value);
                                                    setFieldValue(`${name}.device_performance.max`, event.target.value);
                                                  }}
                                                  placeholder='Max performance value'
                                                  disabled={!isEmpty(values.conditions[index]?.device_performance_list) && !values.conditions[index]?.device_performance_max}
                                                />
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={16} lg={16}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.device_performance`}
                                          label='Device performance list:'
                                        >
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.device_performance_list`}
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch={false}
                                              disabled={(values.conditions[index].device_performance_min || values.conditions[index].device_performance_max) && isEmpty(values.conditions[index]?.device_performance_list)}
                                              name={`${name}.device_performance_list`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.device_performance_list`, value);
                                                setFieldValue(`${name}.device_performance.list`, value);
                                              }}
                                              value={values.conditions[index].device_performance_list}
                                              placeholder="Specify device performance value(s)"
                                              dropdownRender={(menu) => (
                                                <div>
                                                  {menu}
                                                  <Divider style={{ margin: '4px 0' }} />
                                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <FormItem
                                                      className='ant-form-item-col ant-form-item-custom-option'
                                                      name={`${name}.input_device_performance_list`}
                                                    >
                                                      <Input
                                                        size='small'
                                                        type='number'
                                                        style={{ flex: 'auto' }}
                                                        name={`${name}.input_device_performance_list`}
                                                        onKeyDown={(event) => event.stopPropagation()}
                                                        value={values.conditions[index].input_device_performance_list}
                                                        onChange={(event) => {
                                                          if (Number(event.target.value)) {
                                                            setFieldValue(`${name}.input_device_performance_list`, Number(event.target.value), true);
                                                          }
                                                        }}
                                                      />
                                                    </FormItem>
                                                    <Button
                                                      type="primary"
                                                      size='small'
                                                      disabled={
                                                        (!isEmpty(values.conditions[index]?.device_performance_list) ?
                                                          values.conditions[index]?.device_performance_list.includes(values.conditions[index]?.input_device_performance_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_device_performance_list) : false)
                                                        || !isNumber(values.conditions[index]?.input_device_performance_list)
                                                      }
                                                      className='ant-form-item-custom-option-btn'
                                                      onClick={() => {
                                                        setFieldValue(`${name}.device_performance_list`, [ ...values.conditions[index]?.device_performance_list, values.conditions[index]?.input_device_performance_list ], false);
                                                        setFieldValue(`${name}.input_device_performance_list`, null, true);
                                                      }}
                                                    >
                                                      <PlusOutlined />
                                                      Add value
                                                    </Button>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                          </FormItem>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.ram`}
                                          label='Ram (Mb):'
                                        >
                                          <Row>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                style={{ margin: 'unset !important', padding: 'unset !important' }}
                                                name={`${name}.ram_min`}
                                              >
                                                <Input
                                                  type='number'
                                                  name={`${name}.ram_min`}
                                                  onChange={(event) => {
                                                    setFieldValue(`${name}.ram_min`, event.target.value);
                                                    setFieldValue(`${name}.ram.min`, event.target.value);
                                                  }}
                                                  placeholder='Min ram value'
                                                  disabled={!isEmpty(values.conditions[index]?.ram_list) && !values.conditions[index]?.ram_min}
                                                />
                                              </FormItem>
                                            </Col>

                                            <Col span={2}>
                                              <TFormCreateEditAbTestDivider>&ndash;</TFormCreateEditAbTestDivider>
                                            </Col>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                name={`${name}.ram_max`}
                                              >
                                                <Input
                                                  type='number'
                                                  name={`${name}.ram_max`}
                                                  onChange={(event) => {
                                                    setFieldValue(`${name}.ram_max`, event.target.value);
                                                    setFieldValue(`${name}.ram.max`, event.target.value);
                                                  }}
                                                  placeholder='Max ram value'
                                                  disabled={!isEmpty(values.conditions[index]?.ram_list) && !values.conditions[index]?.ram_max}
                                                />
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={16} lg={16}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.ram_list`}
                                          label='Ram list:'
                                        >
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.ram_list`}
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch={false}
                                              disabled={(values.conditions[index].ram_min || values.conditions[index].ram_max) && isEmpty(values.conditions[index]?.ram_list)}
                                              name={`${name}.ram_list`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.ram_list`, value);
                                                setFieldValue(`${name}.ram.list`, value);
                                              }}
                                              placeholder="Specify ram value(s)"
                                              dropdownRender={(menu) => (
                                                <div>
                                                  {menu}
                                                  <Divider style={{ margin: '4px 0' }} />
                                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <FormItem
                                                      className='ant-form-item-col ant-form-item-custom-option'
                                                      name={`${name}.input_ram_list`}
                                                    >
                                                      <Input
                                                        size='small'
                                                        type='number'
                                                        style={{ flex: 'auto' }}
                                                        name={`${name}.input_ram_list`}
                                                        onKeyDown={(event) => event.stopPropagation()}
                                                        value={values.conditions[index].input_ram_list}
                                                        onChange={(event) => {
                                                          if (Number(event.target.value)) {
                                                            setFieldValue(`${name}.input_ram_list`, Number(event.target.value), true);
                                                          }
                                                        }}
                                                      />
                                                    </FormItem>
                                                    <Button
                                                      type="primary"
                                                      size='small'
                                                      disabled={
                                                        (!isEmpty(values.conditions[index]?.ram_list) ?
                                                          values.conditions[index]?.ram_list.includes(values.conditions[index]?.input_ram_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_ram_list) : false)
                                                        || !isNumber(values.conditions[index]?.input_ram_list)
                                                      }
                                                      className='ant-form-item-custom-option-btn'
                                                      onClick={() => {
                                                        setFieldValue(`${name}.ram_list`, [ ...values.conditions[index]?.ram_list, values.conditions[index]?.input_ram_list ], false);
                                                        setFieldValue(`${name}.input_ram_list`, null, true);
                                                      }}
                                                    >
                                                      <PlusOutlined />
                                                      Ram value
                                                    </Button>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                          </FormItem>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.os_versions`}
                                          label='OS versions:'
                                        >
                                          <Row>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                style={{ margin: 'unset !important', padding: 'unset !important' }}
                                                name={`${name}.os_versions_min`}
                                              >
                                                <Input
                                                  name={`${name}.os_versions_min`}
                                                  placeholder='Min OS version'
                                                  disabled={!isEmpty(values.conditions[index]?.os_versions_list) && !values.conditions[index]?.os_versions_min}
                                                />
                                              </FormItem>
                                            </Col>

                                            <Col span={2}>
                                              <TFormCreateEditAbTestDivider>&ndash;</TFormCreateEditAbTestDivider>
                                            </Col>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                name={`${name}.os_versions_max`}
                                              >
                                                <Input
                                                  name={`${name}.os_versions_max`}
                                                  placeholder='Max OS version'
                                                  disabled={!isEmpty(values.conditions[index]?.os_versions_list) && !values.conditions[index]?.os_versions_max}
                                                />
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={16} lg={16}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.os_versions`}
                                          label='OS versions list:'
                                        >
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.os_versions_list`}
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch={false}
                                              disabled={(values.conditions[index].os_versions_min || values.conditions[index].os_versions_max) && isEmpty(values.conditions[index]?.os_versions_list)}
                                              name={`${name}.os_versions_list`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.os_versions_list`, value);
                                              }}
                                              value={values.conditions[index].os_versions_list}
                                              placeholder="Specify os version(s)"
                                              dropdownRender={(menu) => (
                                                <div>
                                                  {menu}
                                                  <Divider style={{ margin: '4px 0' }} />
                                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <FormItem
                                                      className='ant-form-item-col ant-form-item-custom-option'
                                                      name={`${name}.input_os_versions_list`}
                                                    >
                                                      <Input
                                                        size='small'
                                                        style={{ flex: 'auto' }}
                                                        name={`${name}.input_os_versions_list`}
                                                        onKeyDown={(event) => event.stopPropagation()}
                                                        value={values.conditions[index].input_os_versions_list}
                                                        onChange={(event) => {
                                                          setFieldValue(`${name}.input_os_versions_list`, event.target.value, true);
                                                        }}
                                                      />
                                                    </FormItem>
                                                    <Button
                                                      type="primary"
                                                      size='small'
                                                      disabled={
                                                        (!isEmpty(values.conditions[index]?.os_versions_list) ?
                                                          values.conditions[index]?.os_versions_list.includes(values.conditions[index]?.input_os_versions_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_os_versions_list) : false)
                                                        || isEmpty(values.conditions[index]?.input_os_versions_list)
                                                      }
                                                      className='ant-form-item-custom-option-btn'
                                                      onClick={() => {
                                                        setFieldValue(`${name}.os_versions_list`, [ ...values.conditions[index]?.os_versions_list, values.conditions[index]?.input_os_versions_list ], false);
                                                        setFieldValue(`${name}.input_os_versions_list`, '', true);
                                                      }}
                                                    >
                                                      <PlusOutlined />
                                                      OS version
                                                    </Button>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                          </FormItem>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.app_versions`}
                                          label='App versions:'
                                        >
                                          <Row>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                name={`${name}.app_versions_min`}
                                              >
                                                <Input
                                                  name={`${name}.app_versions_min`}
                                                  placeholder='Min app version'
                                                  disabled={!isEmpty(values.conditions[index]?.app_versions_list) && !values.conditions[index]?.app_versions_min}
                                                />
                                              </FormItem>
                                            </Col>

                                            <Col span={2}>
                                              <TFormCreateEditAbTestDivider>&ndash;</TFormCreateEditAbTestDivider>
                                            </Col>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col disable-ant-form-margin'
                                                name={`${name}.app_versions_max`}
                                              >
                                                <Input
                                                  name={`${name}.app_versions_max`}
                                                  placeholder='Max app version'
                                                  disabled={!isEmpty(values.conditions[index]?.app_versions_list) && !values.conditions[index]?.app_versions_max}
                                                />
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={16} lg={16}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.app_versions`}
                                          label='App versions list:'
                                        >
                                          <FormItem
                                            className='ant-form-item-col'
                                            name={`${name}.app_versions_list`}
                                          >
                                            <Select
                                              mode="multiple"
                                              allowClear
                                              showSearch={false}
                                              disabled={(values.conditions[index].app_versions_min || values.conditions[index].app_versions_max) && isEmpty(values.conditions[index]?.app_versions_list)}
                                              name={`${name}.app_versions_list`}
                                              onChange={(value) => {
                                                setFieldValue(`${name}.app_versions_list`, value);
                                              }}
                                              placeholder="Specify app version(s)"
                                              dropdownRender={(menu) => (
                                                <div>
                                                  {menu}
                                                  <Divider style={{ margin: '4px 0' }} />
                                                  <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                                                    <FormItem
                                                      className='ant-form-item-col ant-form-item-custom-option'
                                                      name={`${name}.input_app_versions_list`}
                                                    >
                                                      <Input
                                                        size='small'
                                                        style={{ flex: 'auto' }}
                                                        name={`${name}.input_app_versions_list`}
                                                        onKeyDown={(event) => event.stopPropagation()}
                                                        value={values.conditions[index].input_app_versions_list}
                                                        onChange={(event) => {
                                                          setFieldValue(`${name}.input_app_versions_list`, event.target.value, true);
                                                        }}
                                                      />
                                                    </FormItem>
                                                    <Button
                                                      type="primary"
                                                      size='small'
                                                      disabled={
                                                        (!isEmpty(values.conditions[index]?.app_versions_list) ?
                                                          values.conditions[index]?.app_versions_list.includes(values.conditions[index]?.input_app_versions_list) : false)
                                                        || (errors?.conditions ? !isEmpty(errors?.conditions[index]?.input_app_versions_list) : false)
                                                        || isEmpty(values.conditions[index]?.input_app_versions_list)
                                                      }
                                                      className='ant-form-item-custom-option-btn'
                                                      onClick={() => {
                                                        setFieldValue(`${name}.app_versions_list`, [ ...values.conditions[index]?.app_versions_list, values.conditions[index]?.input_app_versions_list ], false);
                                                        setFieldValue(`${name}.input_app_versions_list`, '', true);
                                                      }}
                                                    >
                                                      <PlusOutlined />
                                                      App version
                                                    </Button>
                                                  </div>
                                                </div>
                                              )}
                                            />
                                          </FormItem>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={24} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.platforms`}
                                          label="Platforms:"
                                        >
                                          <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            name={`${name}.platforms`}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.platforms`, value);
                                            }}
                                            placeholder="Select platform(s)"
                                            value={values.conditions[index].platforms}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {PLATFORM_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.value} value={option.value}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={16} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.law`}
                                          label="Law:"
                                        >
                                          <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            name={`${name}.law`}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.law`, value);
                                            }}
                                            placeholder="Specify law type"
                                            value={values.conditions[index]?.law}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {LAW_TYPES_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.value} value={option.value}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={8} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.lat`}
                                          label="Lat:"
                                        >
                                          <Select
                                            name={`${name}.lat`}
                                            placeholder="Select lat"
                                            value={values.conditions[index].lat}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.lat`, value);
                                            }}
                                          >
                                            {LAT_TYPES_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.value} value={option.value}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name={`${name}.install_dates`}
                                          label="Install dates (min-max):"
                                        >
                                          <Row>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col disable-ant-form-margin'
                                                name={`${name}.install_dates_min`}
                                              >
                                                <DatePicker
                                                  showTime
                                                  format='YYYY-MM-DD HH:mm:ss'
                                                  placeholder='Select min date'
                                                  name={`${name}.install_dates_min`}
                                                  disabled={(!isEmpty(values.conditions[index]?.install_dates_list)
                                                          || values.conditions[index]?.install_dates_special?.statement
                                                          || values.conditions[index]?.install_dates_special?.seconds)
                                                      && !values.conditions[index]?.install_dates_min}
                                                  disabledDate={(date) => date.isSameOrAfter(values.conditions[index].install_dates_max)}
                                                  onChange={(value) => {
                                                    if (moment(value).diff(moment(values.conditions[index].install_dates_max)) > 0) {
                                                      setFieldValue(`${name}.install_dates_max`, null);
                                                    }
                                                    setFieldValue(`${name}.install_dates_min`, moment(value).toISOString());
                                                  }}
                                                />
                                              </FormItem>
                                            </Col>

                                            <Col span={2}>
                                              <TFormCreateEditAbTestDivider>&ndash;</TFormCreateEditAbTestDivider>
                                            </Col>
                                            <Col span={11}>
                                              <FormItem
                                                className='ant-form-item-col'
                                                name={`${name}.install_dates_max`}
                                              >
                                                <DatePicker
                                                  showTime
                                                  format='YYYY-MM-DD HH:mm:ss'
                                                  placeholder='Select max date'
                                                  name={`${name}.install_dates_max`}
                                                  disabled={(!isEmpty(values.conditions[index]?.install_dates_list)
                                                          || values.conditions[index]?.install_dates_special?.statement
                                                          || values.conditions[index]?.install_dates_special?.seconds)
                                                      && !values.conditions[index]?.install_dates_max}
                                                  disabledDate={(date) => !date || date.isSameOrBefore(values.conditions[index].install_dates_min)}
                                                  onChange={(value) => {
                                                    if ((moment(values.conditions[index].install_dates_min).diff(moment(value))) > 0) {
                                                      setFieldValue(`${name}.install_dates_min`, null);
                                                    }
                                                    setFieldValue(`${name}.install_dates_max`, moment(value).toISOString());
                                                  }}
                                                />
                                              </FormItem>
                                            </Col>
                                          </Row>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={24} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.install_dates_special`}
                                          label="Special install dates:"
                                        >
                                          <Input
                                            name={`${name}.install_dates_special.seconds`}
                                            placeholder='Specify seconds'
                                            disabled={(values.conditions[index].install_dates_min
                                                        || values.conditions[index].install_dates_max
                                                        || !isEmpty(values.conditions[index]?.install_dates_list))
                                                    && !values.conditions[index]?.install_dates_special?.statement}
                                            onChange={(event) => {
                                              setFieldValue(`${name}.install_dates_special.seconds`, event.target.value.replace(/\D/g, ''));
                                            }}
                                            addonAfter={
                                              <Form.Item name={`${name}.install_dates_special.statement`} noStyle>
                                                <Select
                                                  placeholder='Select value'
                                                  name={`${name}.install_dates_special.statement`}
                                                  onChange={(value) => {
                                                    setFieldValue(`${name}.install_dates_special.statement`, value);
                                                  }}
                                                  disabled={(values.conditions[index].install_dates_min
                                                          || values.conditions[index].install_dates_max
                                                          || !isEmpty(values.conditions[index]?.install_dates_list))
                                                      && !values.conditions[index]?.install_dates_special?.statement}
                                                  required
                                                  style={{ width: 150 }}
                                                >
                                                  <Option value=''>Not required</Option>
                                                  <Option value=">=">More or equal</Option>
                                                  <Option value="<=">Less or equal</Option>
                                                  <Option value=">">More than</Option>
                                                  <Option value="<">Less than</Option>
                                                </Select>
                                              </Form.Item>
                                            }
                                            style={{ width: '100%' }}
                                          />
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name='fake_install_dates_form_item'
                                          label='Install dates list:'
                                        >
                                          <FieldArray
                                            name={`${name}.install_dates_list`}
                                            value={values.conditions[index]?.install_dates_list}
                                          >
                                            {({ remove, push }) => {
                                              return (
                                                <>
                                                  {!isEmpty(values.conditions[index].install_dates_list) && values.conditions[index].install_dates_list.map((dates, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <FastField name={`dates.[${index}]`} key={index}>
                                                      {() => {
                                                        return (
                                                          <FormItem
                                                            className='ant-form-item-col disable-ant-form-margin'
                                                            name={`${name}.install_dates_list`}
                                                            showInitialErrorAfterTouched
                                                          >
                                                            <div className='da-d-flex'>
                                                              <RangePicker
                                                                className='da-mb-8'
                                                                showTime
                                                                format='YYYY-MM-DD HH:mm:ss'
                                                                name={`${name}.install_dates_list.[${index}]`}
                                                              />
                                                              <Popconfirm title="Sure to delete this date range?" onConfirm={() => remove(index)}>
                                                                <Button
                                                                  style={{ margin: '3px 0 0 5px' }}
                                                                  size='small'
                                                                  danger
                                                                  icon={<DeleteOutlined />}
                                                                />
                                                              </Popconfirm>
                                                            </div>
                                                          </FormItem>
                                                        );
                                                      }}
                                                    </FastField>
                                                  ))}
                                                  <Button
                                                    className='da-mb-16'
                                                    type='primary'
                                                    size='small'
                                                    disabled={!isEmpty(values.conditions[index]?.install_dates_list)
                                                      ? values.conditions[index].install_dates_list.map((item) => isEmpty(item)).includes(true)
                                                      : (values.conditions[index]?.install_dates_special?.statement
                                                            || values.conditions[index]?.install_dates_special?.seconds
                                                            || values.conditions[index].install_dates_min
                                                            || values.conditions[index].install_dates_max)}
                                                    icon={<RiAddFill />}
                                                    onClick={() => push([])}
                                                  >
                                                    Add dates
                                                  </Button>
                                                </>
                                              );
                                            }}
                                          </FieldArray>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={24} md={6} lg={6}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.ab_test.name`}
                                          label="AbTest name:"
                                        >
                                          <Select
                                            allowClear
                                            showSearch
                                            name={`${name}.ab_test.name`}
                                            placeholder="Select AbTest name"
                                            value={values.conditions[index]?.ab_test?.name}
                                            onChange={() => {
                                              setFieldValue(`${name}.ab_test.group`, null);
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {values.abTestOptions.map((option) => {
                                              return (
                                                <Option key={option.name} value={option.name}>
                                                  {option.name}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={24} md={6} lg={6}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.ab_test.group`}
                                          label="AbTest group:"
                                          required={!isEmpty(values.conditions[index]?.ab_test?.name)}
                                        >
                                          <Select
                                            allowClear
                                            disabled={isEmpty(values.conditions[index]?.ab_test?.name)}
                                            name={`${name}.ab_test.group`}
                                            placeholder="Select AbTest group"
                                            value={values.conditions[index]?.ab_test?.group}
                                          >
                                            {!isEmpty(values.abTestOptions) && !isEmpty(values.conditions[index]?.ab_test?.name) ? (values.abTestOptions.filter((item) => item.name === values.conditions[index].ab_test.name)[0]?.data?.groups)?.map((option) => {
                                              return (
                                                <Option key={option.name} value={option.name}>
                                                  {option.name}
                                                </Option>
                                              );
                                            }) : null}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={24} md={4} lg={4}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.users.types`}
                                          label="Users types:"
                                        >
                                          <Select
                                            mode="multiple"
                                            allowClear
                                            showSearch
                                            name={`${name}.users.types`}
                                            placeholder="Select users type(s)"
                                            value={values.conditions[index]?.users?.types}
                                            onChange={(value) => {
                                              setFieldValue(`${name}.users.types`, value);
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) => mkSelectFilterOption(option.children, input)}
                                            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                          >
                                            {USERS_TYPES_OPTIONS.map((option) => {
                                              return (
                                                <Option key={option.value} value={option.value}>
                                                  {option.label}
                                                </Option>
                                              );
                                            })}
                                          </Select>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={24} md={8} lg={8}>
                                        <FormItem
                                          className='ant-form-item-col disable-ant-form-margin'
                                          name='fake_user_id_form_item'
                                          label='Users IDs list:'
                                        >
                                          <FieldArray
                                            name={`${name}.users.list`}
                                            value={values.conditions[index]?.users?.list}
                                          >
                                            {({ remove, push }) => {
                                              return (
                                                <>
                                                  {!isEmpty(values.conditions[index]?.users?.list) && values.conditions[index].users.list.map((users, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <FastField name={`users.${index}`} key={index}>
                                                      {() => {
                                                        return (
                                                          <FormItem
                                                            className='ant-form-item-col disable-ant-form-margin'
                                                            name={`${name}.users.list[${index}]`}
                                                          >
                                                            <div className='da-d-flex'>
                                                              <Input
                                                                fast
                                                                className='da-mb-8'
                                                                name={`${name}.users.list[${index}]`}
                                                                placeholder='Specify user id'
                                                                onChange={(event) => {
                                                                  setFieldValue(`${name}.users.list[${index}]`, event.target.value.replace(/\s/g, ''));
                                                                }}
                                                              />
                                                              <Popconfirm title="Sure to delete this user id?" onConfirm={() => remove(index)}>
                                                                <Button
                                                                  style={{ margin: '3px 0 0 5px' }}
                                                                  size='small'
                                                                  danger
                                                                  icon={<DeleteOutlined />}
                                                                />
                                                              </Popconfirm>
                                                            </div>
                                                          </FormItem>
                                                        );
                                                      }}
                                                    </FastField>
                                                  ))}
                                                  <Button
                                                    type='primary'
                                                    size='small'
                                                    disabled={!isEmpty(values.conditions[index]?.users?.list) ? values.conditions[index].users.list.map((item) => isEmpty(item)).includes(true) : false}
                                                    icon={<RiAddFill />}
                                                    onClick={() => push('')}
                                                  >
                                                    Add user id
                                                  </Button>
                                                </>
                                              );
                                            }}
                                          </FieldArray>
                                        </FormItem>
                                      </Col>

                                      <Col xs={24} sm={12}>
                                        <FormItem
                                          className='ant-form-item-col'
                                          name={`${name}.custom_target`}
                                          label="Custom target:"
                                        >
                                          <Input.TextArea
                                            fast
                                            name={`${name}.custom_target`}
                                            placeholder='Specify custom target'
                                            rows={7}
                                            showCount
                                            maxLength={255}
                                          />
                                        </FormItem>
                                      </Col>

                                      {!isExperimentsPage && (
                                        <Col xs={24} sm={24} md={8} lg={8}>
                                          <FormItem
                                            className='ant-form-item-col disable-ant-form-margin'
                                            name='fake_target_ips_form_item'
                                            label='Target IPs list:'
                                          >
                                            <FieldArray
                                              name={`${name}.ip_addresses`}
                                              value={values.conditions[index]?.ip_addresses}
                                            >
                                              {({ remove, push }) => {
                                                return (
                                                  <>
                                                    {!isEmpty(values.conditions[index]?.ip_addresses) && values.conditions[index].ip_addresses.map((users, index) => (
                                                      // eslint-disable-next-line react/no-array-index-key
                                                      <FastField name={`ip_addresses.${index}`} key={index}>
                                                        {() => {
                                                          return (
                                                            <FormItem
                                                              className='ant-form-item-col disable-ant-form-margin'
                                                              name={`${name}.ip_addresses[${index}]`}
                                                            >
                                                              <div className='da-d-flex'>
                                                                <Input
                                                                  fast
                                                                  className='da-mb-8'
                                                                  name={`${name}.ip_addresses[${index}]`}
                                                                  placeholder='Specify target IP'
                                                                  onChange={(event) => {
                                                                    setFieldValue(`${name}.ip_addresses[${index}]`, event.target.value.replace(/\s/g, ''));
                                                                  }}
                                                                />
                                                                <Popconfirm title="Sure to delete this target IP?" onConfirm={() => remove(index)}>
                                                                  <Button
                                                                    style={{ margin: '3px 0 0 5px' }}
                                                                    size='small'
                                                                    danger
                                                                    icon={<DeleteOutlined />}
                                                                  />
                                                                </Popconfirm>
                                                              </div>
                                                            </FormItem>
                                                          );
                                                        }}
                                                      </FastField>
                                                    ))}
                                                    <Button
                                                      type='primary'
                                                      size='small'
                                                      disabled={!isEmpty(values.conditions[index]?.ip_addresses) ? values.conditions[index].ip_addresses.map((item) => isEmpty(item)).includes(true) : false}
                                                      icon={<RiAddFill />}
                                                      onClick={() => push('')}
                                                    >
                                                      Add target IP
                                                    </Button>
                                                  </>
                                                );
                                              }}
                                            </FieldArray>
                                          </FormItem>
                                        </Col>
                                      )}
                                    </Row>
                                  );
                                }}
                              </FastField>
                            </Card>
                          ))}
                          <Row align='end'>
                            <Button
                              type='primary'
                              icon={<RiAddFill />}
                              onClick={() => push(AB_TEST_INITIAL_CONDITION_FIELD_VALUES)}
                            >
                              Add condition
                            </Button>
                          </Row>
                        </>
                      );
                    }}
                  </FieldArray>
                </Col>

                {isValidConditionsArr.includes(false) && (
                  <Col span={24}>
                    <Alert
                      className='da-mb-18'
                      message="Conditions notification"
                      description={`Please specify more condition ${clearedConditionsIndexMap} details.`}
                      type="error"
                    />
                  </Col>
                )}

                <FormCreateEditAbTestButtonsRow>
                  <FormCreateEditAbTestButtonWr>
                    <Button onClick={onCancel}>
                      Cancel
                    </Button>
                  </FormCreateEditAbTestButtonWr>
                  <FormCreateEditAbTestButtonWr>
                    <SubmitButton
                      style={{ width: '100%' }}
                      loading={isSubmitting}
                      disabled={!isValid || !dirty || isValidConditionsArr.includes(false)}
                    >
                      {isEditForm ? 'Update' : 'Create'}
                    </SubmitButton>
                  </FormCreateEditAbTestButtonWr>
                </FormCreateEditAbTestButtonsRow>
              </Row>
            </Form>
          </Spin>
        );
      }}
    </Formik>
  );
};

FormCreateEditConfigTargeting.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isMixinTargeting: PropTypes.bool,
  isExperimentsPage: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({}).isRequired,
  formErrors: PropTypes.object,
  errors: PropTypes.arrayOf(PropTypes.shape({
    conditions: PropTypes.arrayOf(PropTypes.shape({
      input_os_versions_list: PropTypes.string,
      input_app_versions_list: PropTypes.string,
      input_ram_list: PropTypes.number,
      input_device_performance_list: PropTypes.number,
    })),
  })),
  values: PropTypes.shape({
    status: PropTypes.string,
    countries: PropTypes.array,
    conditions: PropTypes.array,
    device_types: PropTypes.array,
    languages: PropTypes.array,
    condition_type: PropTypes.string,
    abTestOptions: PropTypes.array,
  }),
  isSubmitting: PropTypes.bool.isRequired,
  isEditForm: PropTypes.bool,
  defaultAbTestStatus: PropTypes.string,
  abTestsList: PropTypes.array.isRequired,
};

FormCreateEditConfigTargeting.defaultProps = {
  formErrors: {},
  isEditForm: false,
  isExperimentsPage: false,
  isMixinTargeting: false,
  defaultAbTestStatus: null,
  values: {
    status: '',
    countries: [],
    device_types: [],
    languages: [],
    os_versions: { min: '', max: '' },
    platforms: {},
    app_versions: {},
    conditions: [],
    condition_type: '',
    abTestOptions: [],
  },
};

export default FormCreateEditConfigTargeting;
