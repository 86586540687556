import { Breadcrumb, Button, Card, Col, Input, Layout, Row, Spin, Table, Tabs } from 'antd';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactJson from 'react-json-view';
import isEmpty from 'lodash/isEmpty';
import { MdOutlineSearch, MdOutlineSearchOff } from 'react-icons/md';
import { SearchOutlined } from '@ant-design/icons';
// eslint-disable-next-line lodash/import-scope
import { debounce } from 'lodash';
import { mkQueryParams } from '../../../../lib/mkQueryParams';
import urlPageConfigurations from '../../../../urls/urlPageConfigurations';
import urlPageEditConfiguration from '../../../../urls/urlPageEditConfiguration';
import urlPageCreateConfigTargeting from '../../../../urls/urlPageCreateConfigTargeting';
import urlPageCreateConfigMixin from '../../../../urls/urlPageCreateConfigMixin';
import ConfigurationDetailsCard from '../../components/ConfigurationDetailsCard';
import ConfigurationTargetDetailsCard from '../../components/ConfigurationTargetDetailsCard';
import ConfigurationTargetDetailsConditionsCard from '../../components/ConfigurationTargetDetailsConditionsCard';
import ModalConfirmPublishConfiguration from '../../components/ModalConfirmPublishConfiguration';
import { getConfigurationMixinsTableColumns } from './PageConfigurationDetails.const';
import { hasRights, Permissions } from '../../../../const/permissions';
import HasRights from '../../../../components/HasRights';
import { getQueryParams } from '../../../../lib/getQueryParams';
import { EXPERIMENT_STATUSES_MAP } from '../../../Experiments/pages/PageMixinsExperiments/PageMixinsExperiments.const';
import { BTN_SIDE_MARGIN } from '../../../../const/system';
import { useApp } from '../../../../app/context/AppContext';
import { setItemToQueryParams } from '../../../../lib/setItemToQueryParams';
import { isFulfilledRequestStatus } from '../../../../lib/isRequestSuccess';
import { mkRedirectToDashboardPath } from '../../../../lib/mkRedirectToDashboard';
import {
  deleteConfigTargeting,
  getConfigTargetDetails,
  getConfigurationDetails,
  resetConfigurationDetailsData,
  resetConfigTargetDetailsData,
  selectConfigTargetDetails,
  selectConfigTargetDetailsLoading,
  selectConfigurationDetails,
  selectConfigurationDetailsLoading,
  getConfigMixinsList,
  resetConfigMixinsList,
  selectConfigMixinsListLoading,
  selectConfigMixinsList,
  deleteConfigMixin,
  selectConfigMixinsListPagination,
  publishConfiguration,
  selectPublishConfigurationLoading,
} from '../../feature/configurationDetailsSlice';
import { getAbTestsByParamsListData, selectFormConfigAbTests } from '../../feature/configurationsSlice';



const { Content } = Layout;
const { TabPane } = Tabs;

const PageConfigurationDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { projectSettings } = useApp();
  const { configurationId } = useParams();
  const queryParams = getQueryParams(search);
  const [ currentPage, setCurrentPage ] = useState(queryParams.page);
  const [ currentLimit, setCurrentLimit ] = useState(queryParams.limit);
  const [ currentSorting, setCurrentSorting ] = useState(queryParams.sortedBy);
  const [ currentOrder, setCurrentOrder ] = useState(queryParams.orderBy);
  const [ activeTab, setActiveTab ] = useState(queryParams?.tab ?? 'configuration');
  const [ isActiveNotificationModal, setToggleNotificationModal ] = useState(false);
  const [ isVisibleSearch, setSearchVisible ] = useState(false);
  const [ searchStr, setSearchStr ] = useState(queryParams?.search ?? '');
  const searchRef = useRef(null);

  const configurationDetails = useSelector(selectConfigurationDetails);
  const isLoading = useSelector(selectConfigurationDetailsLoading);
  const configTargetDetails = useSelector(selectConfigTargetDetails);
  const isLoadingConfigTarget = useSelector(selectConfigTargetDetailsLoading);
  const mixinsList = useSelector(selectConfigMixinsList);
  const mixinsListPagination = useSelector(selectConfigMixinsListPagination);
  const isLoadingMixinsList = useSelector(selectConfigMixinsListLoading);
  const isLoadingPublishConfig = useSelector(selectPublishConfigurationLoading);
  const abTestsList = useSelector(selectFormConfigAbTests);

  const preparedMixinsList = !isEmpty(mixinsList) ? mixinsList.filter((item) => [ EXPERIMENT_STATUSES_MAP.APPROVED, EXPERIMENT_STATUSES_MAP.RELEASED ].includes(item.test.status) || isEmpty(item.test.status)) : [];


  useEffect(() => {
    dispatch(getConfigurationDetails(configurationId));
    dispatch(getConfigTargetDetails(configurationId));
    dispatch(getAbTestsByParamsListData('ab-tests?limit=-1&status[0]=archived'));

    if (!isEmpty(searchStr)) {
      setSearchVisible(true);
    }
    return () => {
      dispatch(resetConfigurationDetailsData());
      dispatch(resetConfigTargetDetailsData());
    };
  }, []);

  useEffect(() => {
    dispatch(getConfigMixinsList({
      configurationId,
      params: mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting, searchStr),
    })).then(() => {
      setItemToQueryParams('tab', activeTab);
    });
    return () => {
      dispatch(resetConfigMixinsList());
    };
  }, [ pathname, currentPage, currentLimit, currentOrder, currentSorting, searchStr ]);


  const handleDeleteConfigTargeting = async ({ configurationId, targetingId }) => {
    await dispatch(deleteConfigTargeting({ configurationId, targetingId })).then((response) => {
      if (isFulfilledRequestStatus(response)) {
        dispatch(getConfigTargetDetails(configurationId));
      }
    });
  };

  const handlePublishConfiguration = ({ configurationId }) => {
    dispatch(publishConfiguration(configurationId));
  };

  const handleDeleteConfigurationMixin = async (mixinId) => {
    await dispatch(deleteConfigMixin(mixinId));
    dispatch(getConfigMixinsList({
      configurationId,
      params: mkQueryParams(currentPage, currentLimit, currentOrder, currentSorting, searchStr),
    }));
  };

  const handleChangeTableParams = (pagination, filters, sorter) => {
    if (pagination.current !== queryParams.page) {
      setCurrentPage(Number(pagination.current));
    }
    if (pagination.pageSize !== queryParams.limit) {
      setCurrentLimit(Number(pagination.pageSize));
    }

    if (sorter.hasOwnProperty('column')) {
      if (sorter.field !== queryParams.orderBy) {
        setCurrentOrder(sorter.field);
      }
      if (sorter.order !== queryParams.sortedBy) {
        setCurrentSorting(sorter.order);
      }
    }
  };

  const handleToggleSearch = () => {
    const querySearch = new URLSearchParams(window.location.search);

    setSearchVisible(!isVisibleSearch);
    setTimeout(() => searchRef.current?.focus(), 100);
    setSearchStr('');
    querySearch.delete('search');
    navigate(`${window.location.pathname}?${querySearch.toString()}`, { replace: true });
  };

  const handleChangeSearchParam = (event) => {
    setSearchStr(event.target.value);
    setItemToQueryParams('search', event.target.value);
  };

  const debouncedSearch = useMemo(() => {
    return debounce(handleChangeSearchParam, 1300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  });

  const configurationData = !isEmpty(configurationDetails?.config) ? JSON.parse(configurationDetails.config) : {};

  return (
    <HasRights allowedPermissions={[ Permissions.CONFIGS.CONFIGURATIONS.VIEW_DETAILS ]}>
      <Spin spinning={isLoading || isLoadingConfigTarget} tip="Loading details. Please wait...">
        <Col span={24}>
          <Row justify="space-between">
            <Breadcrumb separator=">">
              <Breadcrumb.Item>
                <Link to={mkRedirectToDashboardPath(projectSettings)}>
                  Home
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={urlPageConfigurations()}>
                  Configurations list
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Configuration details</Breadcrumb.Item>
            </Breadcrumb>

            <ModalConfirmPublishConfiguration
              configurationId={configurationDetails.id}
              isActiveModal={isActiveNotificationModal}
              toggleModal={setToggleNotificationModal}
              handleSubmit={handlePublishConfiguration}
            />

            <Col>
              {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.CREATE ]) && (
                <Button
                  style={BTN_SIDE_MARGIN}
                  disabled={isLoading}
                  className='hovered-btn'
                >
                  <Link to={urlPageCreateConfigMixin({ configurationId: configurationDetails.id, configurationName: configurationDetails.name })}>
                    + Add mixin
                  </Link>
                </Button>
              )}

              {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.PUBLISH ]) && (
                <Button
                  danger
                  style={BTN_SIDE_MARGIN}
                  loading={isLoadingPublishConfig}
                  onClick={() => {
                    setToggleNotificationModal(true);
                  }}
                >
                  Publish configuration
                </Button>
              )}

              {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.UPDATE ]) && (
                <Button
                  style={BTN_SIDE_MARGIN}
                  disabled={isLoading}
                  size='middle'
                  type="primary"
                >
                  <Link to={urlPageEditConfiguration({ configurationId: configurationDetails.id, configurationName: configurationDetails.name })}>
                    Edit configuration
                  </Link>
                </Button>
              )}
            </Col>
          </Row>
        </Col>

        <Content className='layout-content'>
          <Row gutter={[ 16, 16 ]}>
            <Col xs={24} md={24} lg={8}>
              <ConfigurationDetailsCard
                data={configurationDetails}
                isLoading={isLoading}
                hasMixins={mixinsList.length !== 0}
              />
              {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.VIEW_DETAILS ]) && !isEmpty(configTargetDetails) ? (
                <>
                  <ConfigurationTargetDetailsCard
                    data={configTargetDetails}
                    isLoading={isLoading}
                    configurationName={configurationDetails.name}
                    handleDeleteConfigTargeting={handleDeleteConfigTargeting}
                  />
                  {configTargetDetails.conditions.map((condition, index) => (
                    <div className='card-table' key={condition[index]}>
                      <ConfigurationTargetDetailsConditionsCard
                        index={index}
                        condition={condition}
                        isLoading={isLoading}
                      />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.TARGETING.CREATE ]) && isEmpty(configTargetDetails) && (
                    <Button
                      style={{ margin: '10px 0' }}
                      className='hovered-btn'
                      disabled={isLoading}
                    >
                      <Link to={urlPageCreateConfigTargeting({ configurationId: configurationDetails.id, configurationName: configurationDetails.name })}>
                        + Create config target
                      </Link>
                    </Button>
                  )}
                </>
              )}
            </Col>

            <Col xs={24} md={24} lg={16}>
              <Card className='card-has-table'>
                <Tabs
                  defaultActiveKey={!isEmpty(activeTab) ? activeTab : 'configuration'}
                  onTabClick={(value) => {
                    setActiveTab(value);
                    setItemToQueryParams('tab', value);
                  }}
                  tabBarExtraContent={activeTab === 'mixins' && (
                    <Row>
                      <div style={{ marginRight: '15px' }}>
                        {isVisibleSearch && (
                          <Input
                            ref={searchRef}
                            id='search'
                            name='search'
                            size='small'
                            defaultValue={searchStr}
                            placeholder='Search mixin...'
                            allowClear={!isEmpty(searchStr)}
                            onChange={debouncedSearch}
                            prefix={isVisibleSearch ? <SearchOutlined fill='#9393ab' /> : <span />}
                          />
                        )}
                      </div>
                      <Button
                        size='small'
                        className={!isVisibleSearch ? 'hovered-btn' : ''}
                        style={{ fontSize: '17px' }}
                        onClick={handleToggleSearch}
                      >
                        {isVisibleSearch ? <MdOutlineSearchOff /> : <MdOutlineSearch />}
                      </Button>
                    </Row>)}
                >
                  <TabPane tab="Configuration" key="configuration">
                    <ReactJson
                      src={configurationData}
                      theme="summerfruit:inverted"
                    />
                  </TabPane>
                  {hasRights([ Permissions.CONFIGS.CONFIGURATIONS.MIXINS.VIEW_LIST ]) && (
                    <TabPane tab="Mixins" key="mixins">
                      <Table
                        sticky
                        onChange={handleChangeTableParams}
                        columns={getConfigurationMixinsTableColumns({ handleDeleteConfigurationMixin, configurationName: configurationDetails.name, abTestsList })}
                        loading={isLoadingMixinsList}
                        rowKey="id"
                        dataSource={preparedMixinsList}
                        pagination={{
                          size: 'small',
                          current: mixinsListPagination.current_page,
                          pageSize: mixinsListPagination.per_page,
                          total: mixinsListPagination.total,
                          position: [ 'bottomCenter' ],
                          pageSizeOptions: [ '10', '25', '50' ],
                          showSizeChanger: true,
                        }}
                        scroll={{ y: 550 }}
                      />
                    </TabPane>
                  )}
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Content>
      </Spin>
    </HasRights>
  );
};

export default PageConfigurationDetails;
